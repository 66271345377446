import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import Head from '@/components/head/index';
import { getcustomerlevel, getStructureListArr } from '@/api/PublicAPI';
import { MyLiberumList, MyLiberumreceive, MyLiberumdistribution } from '@/api/ceshi_xiugai/Sendcustomerstohighseas';
import { agentFlowerNameList, Majorlist, getLabelpeer as _getLabelpeer, FollwupIndex } from '@/api/ceshi_xiugai/whole';
import callRecord from '@/components/callRecord/index';
export default {
  components: {
    Head: Head,
    mailListUp: mailListUp,
    callRecord: callRecord
  },
  data: function data() {
    return {
      // 系统教程
      tab: 1,
      activeName: 'first',
      course: this.$route.query.course,
      prohibit: false,
      auth_id: '',
      loading: true,
      // 系统教程
      total_allow: 0,
      listThematicChecked: [],
      user_arr: [{
        id: 1,
        realname: '可操作数据',
        num: 0
      }],
      // 埋点是否显示
      BuriedData1: [],
      Buried: {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      },
      start_follow_time: '',
      //时间（开始）
      end_follow_time: '',
      //时间（结束）
      typeShow: false,
      operatorShow: false,
      userNameshow: false,
      BelongingShow: false,
      operationDataShow: false,
      // 埋点是否显示
      flower_id: '',
      //学员来源id
      flower_Array: [],
      //学员来源
      title: '部门二级公海池',
      value1: '',
      is_shi: this.$store.getters.isShi,
      get_type: 6,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      customer_id: '',
      customer: [],
      //学员级别
      customer_name: '',
      //	微信/手机号/学员称呼
      wxnum_mobile_qq: '',
      tableData: [],
      Alltableoptions: [],
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      condition: [],
      radio: false,
      tempId: '',
      editForm: {
        type: 2,
        customer_levels: '',
        //学员级别（传id）
        source_client_id: '',
        //花名（学员来源） 传id
        create_stime: '',
        //创建开始时间
        create_etime: '',
        //创建结束时间
        ittnzy: '',
        //意向专业（1,2,3）
        next_follow_stime: '',
        //下次回访日期开始时间
        next_follow_etime: '',
        //下次回访日期结束时间
        start_lib_time: '',
        end_lib_time: '',
        create_realname: '',
        //创建人
        structure_ids: '',
        //原始部门 （1,2,3）
        labelpeers: '',
        //客户标签(1,2,3)
        notes: '',
        //备注
        create_time_order: '',
        //创建日期排序 1正序 2倒序 0不排序
        follow_time_order: '',
        //最后跟进日期排序 1正序 2倒序 0不
        regression_time_order: '',
        //公海日期日期排序 1正序 2倒序 0不
        next_time_order: '',
        //下次回访日期排序 1正序 2倒序 0不排
        now_follow_num_order: '',
        //跟进次数排序 1正序 2倒序 0不排
        customer_name: '',
        //手机号 微信 qq号 用户名
        wxnum_mobile_qq: '',
        paginate: '',
        page: '',
        mobile_address: '',
        accountName: '',
        is_allow: '',
        calls_num: '',
        calle_num: '',
        call_num_order: '0',
        // 未跟进时长
        end_follow_time: '',
        start_follow_time: ''
      },
      finalFollowshow: false,
      accountShow: false,
      attributionshow: false,
      nexttimeshow: false,
      //下次回访日期
      lasttimeshow: false,
      //最后跟进日期
      seaShow: false,
      createtimeshow: false,
      //创建日期
      ittnzyshow: false,
      //意向专业
      createnameshow: false,
      //创建人
      remarksshow: false,
      //备注
      labelpeersshow: false,
      //客户标签
      structure_idshow: false,
      //组织部门
      accountName: '',
      attribution: '',
      createtime: '',
      //创建日期
      showittnzy: '',
      //意向专业
      Intendeds: [],
      //意向专业
      nexttime: '',
      //下次回访日期
      finalFollow: '',
      //最后跟进日期
      finalFollowNew: '',
      //未跟进时长
      seaDate: '',
      //公海日期
      create_realname: '',
      //创建人
      structure_id: '',
      //组织部门
      bumenoptions: [],
      labelpeers: '',
      //客户标签
      labelpeersEs: [],
      remarks: '',
      //备注
      followUp: false,
      //跟进弹窗
      activities: [],
      reverse: true,
      buried: [],
      receive: '',
      distribution: '',
      dialogTableVisible: false,
      callCid: '',
      cusName: '',
      cusPhone: '',
      callNumShow: false,
      nowTimeL: '',
      timeArry: []
    };
  },
  mounted: function mounted() {
    this.buried = this.$store.getters.JsonArr1;
    if (this.course != 1) {
      this.GetList();
      this.flowerArray();
      this.Getcustomer();
      this.majorDedMaj(); //意向专业
      this.getStructure(); //组织部门
      this.getLabelpeer();
    } else {}
    this.nowTimeL = Number(new Date().getTime());
    this.timeArry = [{
      id: 0,
      title: '3个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 90, this.nowTimeL]
    }, {
      id: 1,
      title: '4个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 120, this.nowTimeL]
    }, {
      id: 2,
      title: '6个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 180, this.nowTimeL]
    }, {
      id: 3,
      title: '8个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 240, this.nowTimeL]
    }, {
      id: 4,
      title: '10个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 300, this.nowTimeL]
    },
    // {
    //     id: 5,
    //     title: '12个月未跟进',
    //     value: [this.nowTimeL - 3600 * 1000 * 24 * 365, this.nowTimeL]
    // },
    {
      id: 6,
      title: '12个月以上未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 365, this.nowTimeL - 3600 * 1000 * 24 * 365]
    }];
  },
  methods: {
    // 埋点列表筛选
    operatorClick: function operatorClick() {
      //操作人
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    userNamesClick: function userNamesClick() {
      //用户名
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    // 埋点列表筛选
    handleClick: function handleClick() {
      if (this.activeName == 'second') {
        this.query.page = 1;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
      this.structure_id = '';
      this.finalFollow = '';
      this.typeShow = false;
      this.operatorShow = false;
      this.userNameshow = false;
      this.BelongingShow = false;
      this.operationDataShow = false;
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this.BuriedData1 = res.data.data;
        _this.total = res.data.total;
      });
    },
    buriedPoint: function buriedPoint(name) {
      var _this2 = this;
      this.buried.forEach(function (item) {
        if (item.title == name) {
          _this2.auth_id = item.id;
        }
      });
      return this.auth_id;
    },
    //学员级别list
    Getcustomer: function Getcustomer() {
      var _this3 = this;
      getcustomerlevel().then(function (res) {
        _this3.customer = res.data;
      });
    },
    flowerArray: function flowerArray() {
      var _this4 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this4.flower_Array = res.data;
      });
    },
    getStructure: function getStructure() {
      var _this5 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this5.bumenoptions = respomse.data;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this6 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this6.Intendeds = res.data.major_list;
      });
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this7 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this7.labelpeersEs = res.data;
        // this.customer = res.data;
      });
    },
    radioFix: function radioFix() {
      if (this.listThematicChecked.length > 1) {
        this.listThematicChecked.splice(0, 1);
      }
      this.editForm.is_allow = String(this.listThematicChecked);
      this.query.page = 1;
      this.GetList();
    },
    viewRecord: function viewRecord(id, name, phone) {
      this.dialogTableVisible = true;
      this.callCid = id;
      this.cusName = name;
      this.cusPhone = phone;
    },
    callNums: function callNums() {
      //拨打次数筛选
      this.query.page = 1;
      if (this.editForm.calls_num == '' && this.editForm.calle_num == '') {
        this.editForm.calls_num = '';
        this.editForm.calle_num = '';
        this.GetList();
      } else if (this.editForm.calls_num == '' || this.editForm.calle_num == '') {
        this.$message({
          message: '请输入完整次数段',
          type: 'warning'
        });
      } else if (Number(this.editForm.calls_num) > Number(this.editForm.calle_num)) {
        this.$message({
          message: '请输入正确次数范围',
          type: 'warning'
        });
      } else {
        this.GetList();
      }
    },
    GetList: function GetList() {
      var _this8 = this;
      this.editForm.paginate = this.query.pagesize;
      this.editForm.page = this.query.page;
      this.loading = true;
      this.editForm.auth_rule_id = this.buriedPoint('查看');
      MyLiberumList(this.editForm).then(function (res) {
        _this8.loading = false;
        _this8.tableData = res.data.data;
        _this8.query.total = res.data.total;
        _this8.total_allow = res.data.total_allow;
      });
    },
    //学员级别查询
    customerhand: function customerhand(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 学员级别的最后一位ID
      this.editForm.customer_levels = share;
      this.query.page = 1;
      this.GetList();
    },
    //学员来源查询
    flowerArrayEs: function flowerArrayEs() {
      var _this9 = this;
      this.query.page = 1;
      if (this.flower_id !== '') {
        this.editForm.source_client_id = this.flower_id;
        this.$nextTick(function () {
          _this9.GetList();
        });
      } else {
        this.editForm.source_client_id = '';
        this.$nextTick(function () {
          _this9.GetList();
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      this.loading = true;
      this.editForm.customer_name = this.customer_name;
      this.query.page = 1;
      this.GetList();
    },
    //查询
    wxnumMobileQq: function wxnumMobileQq() {
      this.loading = true;
      this.editForm.wxnum_mobile_qq = this.wxnum_mobile_qq;
      this.query.page = 1;
      this.GetList();
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this10 = this;
      this.query.pagesize = val;
      if (this.course != 1) {
        this.$nextTick(function () {
          _this10.GetList();
        });
      } else {
        this.statisticalTable(this.query.page, val);
      }
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this11 = this;
      this.query.page = val;
      if (this.course != 1) {
        this.$nextTick(function () {
          _this11.GetList();
        });
      } else {
        this.statisticalTable(val, this.query.pagesize);
      }
    },
    //部分复选框禁用
    selectable: function selectable(row, index) {
      if (row.allow_status == 0) {
        return false;
      } else {
        return true;
      }
    },
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      this.tempId = this.Alltableoptions.map(function (item) {
        return item.customer_data_id;
      }).join(',');
    },
    //领取
    Receive: function Receive() {
      var _this12 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认领取该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          MyLiberumreceive({
            customer_data_ids: String(_this12.tempId),
            auth_rule_id: _this12.buriedPoint('领取')
          }).then(function (res) {
            _this12.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this12.$nextTick(function () {
              //获取列表方法
              _this12.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选择领取项');
      }
    },
    //分配
    Distribution: function Distribution() {
      if (this.Alltableoptions.length !== 0) {
        this.staffSelectVisible = true;
        this.radio = true;
      } else {
        this.$message('请选中移交项');
      }
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this13 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认分配该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            customer_data_ids: String(_this13.tempId),
            user_id: result.toString(),
            auth_rule_id: _this13.buriedPoint('分配')
          };
          MyLiberumdistribution(para).then(function (res) {
            _this13.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this13.$nextTick(function () {
              //获取列表方法
              _this13.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中分配项');
      }
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '创建人') {
        this.createnameshow = true;
      } else if (item === '账户ID') {
        this.accountShow = true;
      } else if (item === '备注') {
        this.remarksshow = true;
      } else if (item === '组织部门') {
        this.structure_idshow = true;
      } else if (item == '客户标签') {
        this.labelpeersshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '进入公海日期') {
        this.seaShow = true;
      } else if (item == '归属地') {
        this.attributionshow = true;
      } else if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      } else if (item === '拨打次数') {
        this.callNumShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      var _this14 = this;
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.nexttime = '';
        this.$nextTick(function () {
          _this14.GetList(_this14.editForm.page, _this14.editForm.pagesize);
        });
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        // this.editForm.start_follow_time = '';
        // this.editForm.end_follow_time = '';
        this.finalFollow = '';
        this.FinalFollowUpEs('new');
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '进入公海日期') {
        this.seaShow = false;
        this.editForm.start_regression_time = '';
        this.editForm.end_regression_time = '';
        this.seaDate = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.createtime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
        this.editForm.ittnzy = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '创建人') {
        this.createnameshow = false;
        this.editForm.create_realname = '';
        this.$nextTick(function () {
          _this14.GetList(_this14.editForm.page, _this14.editForm.pagesize);
        });
      } else if (item === '账户ID') {
        this.accountShow = false;
        this.editForm.account_id = '';
        this.account = '';
        this.$nextTick(function () {
          _this14.GetList(_this14.editForm.page, _this14.editForm.pagesize);
        });
      } else if (item === '备注') {
        this.remarksshow = false;
        this.remarks = '';
        this.editForm.notes = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '组织部门') {
        this.structure_idshow = false;
        this.editForm.structure_ids = '';
        this.structure_ids = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
        this.editForm.labelpeers = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '归属地') {
        this.attributionshow = false;
        this.attribution = '';
        this.editForm.mobile_address = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else if (item === '拨打次数') {
        this.callNumShow = false;
        this.editForm.calls_num = '';
        this.editForm.calle_num = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //创建人
    createname: function createname() {
      this.editForm.create_realname = this.create_realname;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    accountClick: function accountClick() {
      this.editForm.account_id = this.accountName;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      this.query.page = 1;
      if (this.createtime == null) {
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.create_stime = this.createtime[0].toString().substring(0, 10);
        this.editForm.create_etime = this.createtime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //回访时间选择
    nextDateTime: function nextDateTime(item) {
      this.query.page = 1;
      if (this.nexttime == null) {
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.editForm.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //最后跟进日期筛选
    FinalFollowUpEs: function FinalFollowUpEs(type) {
      this.query.page = 1;
      // 如果 -- 未跟进时长不为空 按 未跟进时长 算
      if (this.finalFollowNew != null && this.finalFollowNew != '') {
        this.editForm.start_follow_time = '';
        // this.editForm.start_follow_time = this.finalFollowNew[0].toString().substring(0, 10);
        this.editForm.end_follow_time = this.finalFollowNew[0].toString().substring(0, 10);
      } else {
        if (this.finalFollow == null || this.finalFollow == '') {
          this.editForm.start_follow_time = '';
          this.editForm.end_follow_time = '';
        } else {
          this.editForm.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
          this.editForm.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        }
      }
      if (type != 'new') {
        this.FinalFollowUpEsSelect();
      }
    },
    // 方便区分
    FinalFollowUpEsSelect: function FinalFollowUpEsSelect() {
      if (this.course != 1) {
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.Buried.search_create_time = this.editForm.start_follow_time + ',' + this.editForm.end_follow_time;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    //进入公海日期筛选
    seaDateEs: function seaDateEs() {
      this.query.page = 1;
      if (this.seaDate == null) {
        this.editForm.start_regression_time = '';
        this.editForm.end_regression_time = '';
        if (this.course != 1) {
          this.GetList(this.editForm.page, this.editForm.pagesize);
        } else {
          this.Buried.search_create_time = this.editForm.start_regression_time + ',' + this.editForm.end_regression_time;
          this.statisticalTable(this.query.page, this.query.pagesize);
        }
      } else {
        this.editForm.start_regression_time = this.seaDate[0].toString().substring(0, 10);
        this.editForm.end_regression_time = this.seaDate[1].toString().substring(0, 10);
        if (this.course != 1) {
          this.GetList(this.editForm.page, this.editForm.pagesize);
        } else {
          this.Buried.search_create_time = this.editForm.start_regression_time + ',' + this.editForm.end_regression_time;
          this.statisticalTable(this.query.page, this.query.pagesize);
        }
      }
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.editForm.ittnzy = this.showittnzy;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      this.query.page = 1;
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.editForm.structure_ids = adad;
      if (this.course == 1) {
        this.Buried.search_structure_ids = adad;
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else {
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.editForm.labelpeers = item;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //备注
    remarksClick: function remarksClick() {
      this.editForm.notes = this.remarks;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    attributionClick: function attributionClick() {
      this.editForm.mobile_address = this.attribution;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      this.query.page = 1;
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.editForm.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.create_time_order = '2';
        } else {
          this.editForm.create_time_order = '0';
        }
        this.editForm.follow_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.editForm.follow_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.follow_time_order = '2';
        } else {
          this.editForm.follow_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'regression_time') {
        //进入公海日期
        if (column.order == 'ascending') {
          this.editForm.regression_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.regression_time_order = '2';
        } else {
          this.editForm.regression_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.editForm.next_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.next_time_order = '2';
        } else {
          this.editForm.next_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'all_call_num') {
        //拨打次数
        if (column.order == 'ascending') {
          this.editForm.call_num_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.call_num_order = '2';
        } else {
          this.editForm.call_num_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'now_follow_num') {
        //跟进次数
        if (column.order == 'ascending') {
          this.editForm.now_follow_num_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.now_follow_num_order = '2';
        } else {
          this.editForm.now_follow_num_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.call_num_order = '0';
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this15 = this;
      //跟进弹窗
      this.followUp = true;
      FollwupIndex({
        id: id.customer_id,
        customer_data_id: id.customer_data_id,
        is_all: 1,
        is_realname: 0
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this15.activities = res.data;
      });
    }
  }
};